import React, { Fragment } from 'react';

import mainImage from '../assets/main.jpg';

import classes from './Home.module.css';

const Home = () => {
  const myStyle = {
    backgroundImage: `url(${mainImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };

  return (
    <Fragment>
      <div style={myStyle}>
        <h1 className={classes.heading1}>OdyWeb</h1>
        <h2 className={classes.heading2}>
          Consulting &amp; Development Services
        </h2>
        <p className={classes.middle}>
          <a href="mailto:info@odyweb.com" className={classes.email_link}>
            info@odyweb.com
          </a>
        </p>
        <p className={classes.footer}>
          &copy; OdyWeb, LLC
          <br />
          All Rights Reserved.
        </p>
      </div>
    </Fragment>
  );
};

export default Home;
